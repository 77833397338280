import React, { useEffect, useMemo, useState } from 'react';
import i18n from 'i18next';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import Home from 'src/screens/Home';
import KYCCompleted from 'src/screens/KYCCompleted';
import Login from 'src/screens/Login';
import Privacy from 'src/screens/Privacy';
import UserAgreement from 'src/screens/UserAgreement';
import CookiePolicy from 'src/screens/CookiePolicy';
import AboutUs from 'src/screens/AboutUs';
import Faq from 'src/screens/Faq';
import Request from 'src/screens/Request';
import Referral from 'src/screens/Referral';
import Register from 'src/screens/Register';
import Profile from 'src/screens/Profile';
import TransactionDetailsScreen from 'src/screens/TransactionDetails';
import { RoutePath } from 'src/routes/path';
import { useGlobalStore } from 'src/stores';
import { useRefreshToken } from 'src/hooks/queries/useAuth';
import { FlashScreen } from 'src/components/Common/FlashScreen';
import TransactionHistory from 'src/Features/TransactionHistory';
import { getToken } from 'src/utils/localStorage';
import MainLayout from 'src/layout/Main';
import SecondaryLayout from 'src/layout/Secondary';
import PrimaryLayout from 'src/layout/Primary';
import ProtectedRoute from './ProtectedRoute';
import EmptyLayout from 'src/layout/Empty';

const publicRoutes = [
  {
    exact: true,
    path: RoutePath.LOGIN,
    component: Login,
    layout: SecondaryLayout,
  },
  {
    exact: true,
    path: RoutePath.REGISTER,
    component: Register,
    layout: SecondaryLayout,
  },
  {
    exact: true,
    path: RoutePath.PRIVACY,
    component: Privacy,
    layout: MainLayout,
  },
  {
    exact: true,
    path: RoutePath.USER_AGREEMENT,
    component: UserAgreement,
    layout: MainLayout,
  },
  {
    exact: true,
    path: RoutePath.COOKIE_POLICY,
    component: CookiePolicy,
    layout: MainLayout,
  },
  {
    exact: true,
    path: RoutePath.COOKIE_POLICY,
    component: CookiePolicy,
    layout: MainLayout,
  },
  {
    exact: true,
    path: RoutePath.ABOUT_US,
    component: AboutUs,
    layout: MainLayout,
  },
  {
    exact: true,
    path: RoutePath.FAQ,
    component: Faq,
    layout: MainLayout,
  },
  {
    exact: true,
    path: RoutePath.REQUEST,
    component: Request,
    layout: MainLayout,
  },
  {
    exact: true,
    path: RoutePath.REF,
    component: Referral,
    layout: EmptyLayout,
  },
  {
    exact: true,
    path: RoutePath.KYCCompleted,
    component: KYCCompleted,
    layout: EmptyLayout,
  },
];

const protectRoutes = [
  {
    exact: true,
    path: RoutePath.HOME,
    component: Home,
    layout: PrimaryLayout,
  },
  {
    exact: true,
    path: RoutePath.TRANSACTION_HISTORY,
    component: TransactionHistory,
    layout: MainLayout,
  },
  {
    exact: true,
    path: RoutePath.PROFILE,
    component: Profile,
    layout: MainLayout,
  },
  {
    exact: true,
    path: RoutePath.TRANSACTION_DETAILS,
    component: TransactionDetailsScreen,
    layout: MainLayout,
  },
];

function AppRoutes() {
  const { isAuth } = useGlobalStore();
  const { mutateAsync } = useRefreshToken();
  const [refreshLoading, setRefreshLoading] = useState(true);

  const isAuthenticated = useMemo(() => isAuth, [isAuth]);

  useEffect(() => {
    // TODO PASS TOKEN IN HERE
    const handleRefresh = async () => {
      await mutateAsync();
      setRefreshLoading(false);
    };

    const token = getToken();
    if (token) {
      handleRefresh();
    } else {
      setRefreshLoading(false);
    }
  }, []);

  if (refreshLoading) {
    return <FlashScreen />;
  }

  const lang = `/${i18n.language}`;
  return (
    <Router basename={lang}>
      <Switch>
        {publicRoutes.map((route) => (
          <Route
            key={route.path}
            exact
            path={`${route.path}`}
            render={() => {
              return (
                <route.layout>
                  <route.component />
                </route.layout>
              );
            }}
          />
        ))}
        {!refreshLoading &&
          protectRoutes.map((route) => (
            <ProtectedRoute
              exact
              key={route.path}
              path={`${route.path}`}
              isAuthenticated={isAuthenticated}
              // eslint-disable-next-line react/no-unstable-nested-components
              component={() => (
                <route.layout>
                  <route.component />
                </route.layout>
              )}
            />
          ))}
      </Switch>
    </Router>
  );
}

export default AppRoutes;

