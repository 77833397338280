import React, { useEffect } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';

import _ from 'lodash';

import { useBuyCurrencyStore } from 'src/stores/buyCurrency';
import CardWrapper from 'src/components/Common/CardWrapper';
import { useTranslation } from 'react-i18next';
import { usePayment } from 'src/hooks/queries/useCurrency';

export const BankTransfer = () => {
  const { i18n, t } = useTranslation();
  const {
    transactionState: { id },
  } = useBuyCurrencyStore();
  const { mutateAsync: payment, isLoading } = usePayment();

  const submitPayment = async () => {
    await payment({
      payment_id: id as string,
      payment_option: 'Bank Transfer',
      country: 'FR',
      state: 'HCM city',
      address: '300 Nguyen Van Quy',
      city: 'HCM city',
      zip: '500000',
      card_holder_name: '',
      card_number: '',
      expiration_date: '',
      cardSaveNumber: '',
      cvv: '',
    });
  };
  useEffect(() => {
    if (id) {
      submitPayment();
    }
  }, []);
  return (
    <CardWrapper>
      <Box display='flex' flexDirection='column' alignItems='center'>
        <Typography variant='h4' fontWeight='bold' mb={1}>
          {t('bankTransfer')}
        </Typography>
      </Box>
      <Box display='flex' flexDirection='column' alignItems={'center'}>
        <CircularProgress />
        <Typography mb={1} color='grey.400'>
          Fetching payment options
        </Typography>
      </Box>
    </CardWrapper>
  );
};

export default BankTransfer;
